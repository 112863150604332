import React from 'react';

export default function Contact({locale}) {
	return (
		<div className="contact">
			<div className="contact__header">
				<h1>{locale.contact.header}</h1>
				<p>{locale.contact.text}</p>
			</div>
			<main className="contact__main">
				<div className="participant">
					<img src="/images/klapka.jpg" />
					<h3>{locale.contact.participant1.name}</h3>
					<h4>{locale.contact.participant1.position}</h4>
					<p>{locale.contact.participant1.text}</p>
					<div className="participant__contact">
						<a href="tel:420-604-189-483">Tel: +420 604 189 483</a>
						<br />
						<a href="mailto:pavel.klapka@innovasoft.cz">E-mail: pavel.klapka@innovasoft.cz</a>
					</div>
				</div>
				<div className="participant">
					<img src="/images/dduda.jpg" />
					<h3>{locale.contact.participant2.name}</h3>
					<h4>{locale.contact.participant2.position}</h4>
					<p>{locale.contact.participant2.text}</p>
					<div className="participant__contact">
						<a href="tel:420-774-303-300">Tel: +420 722 945 642 </a>
						<br />
						<a href="mailto:dusan.duda@innovasoft.cz">E-mail: dusan.duda@innovasoft.cz</a>
					</div>
				</div>
				<div className="participant">
					<img src="/images/jalowiczor.jfif" />
					<h3>{locale.contact.participant3.name}</h3>
					<h4>{locale.contact.participant3.position}</h4>
					<p>{locale.contact.participant3.text}</p>
				</div>
				<div className="participant">
					<img src="/images/userIcon.png" className="participantIcon" />
					<h3>{locale.contact.participant4.name}</h3>
					<h4>{locale.contact.participant4.position}</h4>
					<p>{locale.contact.participant4.text}</p>
				</div>
			</main>
		</div>
	);
}
